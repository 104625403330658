.checkout-form-title {

    padding-top: 3rem;

}

.checkout-form-title h6 {
    color: #383838;
    padding-bottom: 2rem;
    font-size: 18px;
    font-weight: 400;
    border-bottom: 1px solid #E0E0E0;

}

.order-summary {

    width: 368px;
    height: 729px;
    border: 1px solid #E0E0E0;
    background-color: #F7F7F7;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 8px;

}

.form-control {
    width: 370px;
    height: 60px;
}

.form-select {
    width: 370px;
    height: 60px;
}

.exampleFormControlTextarea1 {

    width: 768px;
    height: 120px;

}

.order-summary h5 {
    text-align: center;
    font-size: 24px;
    color: #383838;
    padding-top: 2rem;
}

.orderrow {

    border-bottom: 1px solid #E0E0E0;
    padding: 2rem;
    margin: 0px !important;
    font-size: 18px;

}

.subtotal-sec {

    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 1rem;

}

.subtotal-sec span {

    font-size: 18px;
    font-weight: 500;
}

.subtotal-sec p {

    float: right;
    font-size: 18px;
    font-weight: 500;

}

.tax-sec {

    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #E0E0E0;

}

.tax-sec span {

    font-size: 18px;
    font-weight: 500;
}

.tax-sec p {

    float: right;
    font-size: 18px;
    font-weight: 500;

}

.total-sec {


    padding: 2rem;
    border-bottom: 1px solid #E0E0E0;
}

.total-sec span {

    font-size: 20px;
    font-weight: 500;
}

.total-sec p {

    float: right;
    font-size: 24px;
    font-weight: 600;

}

.payment-sec {


    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;    
}

.payment-sec span {

    font-size: 20px;
    font-weight: 600;
}

.check-payment-method{


padding-top: 1rem;

}

.cash-label, .credit-label{


    font-size: 18px;
}

.cash-label label input {

    margin-right: 10px;
}
.credit-label label input {

    margin-right: 10px;
}

.Checkout-btn{
    width: 315px;
    height: 50px;
    /* UI Properties */
    background: #95D354 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: 0px;
    color: white;
    margin-top: 5rem;
}



#exampleInputnote{

    width: 94.5%;
    height:120px;
}


@media (max-width: 480px) {

    .checkout-form-title{

        justify-content: center;
        align-items: center;

    }

    .checkout-form-title form input{

        width: 100%;
    }
    .checkout-form-title form select{

        width: 100%;
    }

    #exampleInputnote{

        width: 100%;
    }


    .order-summary {

        width: 100%;
        height: auto;
        border: 1px solid #E0E0E0;
        background-color: #F7F7F7;
        margin-top: 1rem;
        margin-bottom: 2rem;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
    
    }

    .Checkout-btn{
        width: 100%;
        height: 50px;
        /* UI Properties */
        background: #95D354 0% 0% no-repeat padding-box;
        border-radius: 5px;
        border: 0px;
        color: white;
        margin-top: 2rem;
    }
}


