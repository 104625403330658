.fa-thin{

    font-weight: 300;
    font-size: 15px;
    margin-right: 10px;
    opacity: 0.1
}
.offcanvas-body{
  display: none !important;
}
.login-btn{

        background-color: transparent;
        color: black;
        border: 0px;
        border-right: 2px solid #8B8B8B28;
        border-radius: 0px;
        width: 90px;
        height: 38px;
        border-radius: 4px;
        
        
}
.dddd{
  margin-right: -1.5rem;
}
.navbar-nav .nav-item4 {
  order: -1; /* Move the nav items to the right */
  z-index: 8;
}
.navbar-nav{
  margin-left: 4rem !important;
}


.rak-logo{

  width: 132px;
  height: 47px;
}

.signup-btn{
  
        border: 0px;
        width: 106px;
        height: 38px;
        background-color:#95D354;
        border-radius: 4px;
        color: white;
        z-index: 6;
}

.dropdown-container {
  display: inline-block;
  padding: 10px;
}
.dropdown-container .dropdown {
  position: relative;
}
.dropdown-container .dropdown[open] .with-down-arrow::after {
  content: "";
}
.dropdown-container .dropdown[open] summary {
  background: #ffffff10;
}
.dropdown-container .dropdown summary {
  list-style: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 6px;
}
.dropdown-container .dropdown summary.avatar {
  border-radius: 50px;
}
.dropdown-container .dropdown summary.avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: inline-block;
  margin: 0;
}
.dropdown-container .dropdown summary .with-down-arrow {
  display: inline-flex;
  padding: 5px;
  align-items: center;
  color: #fff;
  line-height: 1;
}
.dropdown-container .dropdown summary .with-down-arrow::after {
  content: "";
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}
.dropdown-container .dropdown.left ul {
  left: 0;
}
.dropdown-container .dropdown.right ul {
  right: 0;
}
.dropdown-container .dropdown ul {
  padding: 0;
  margin: 0;
  box-shadow: 0 0 10px #00000030;
  min-width: max-content;
  position: absolute;
  top: 100%;
  border-radius: 10px;
  background-color: #fff;
  z-index: 2;
}
.dropdown-container .dropdown ul li {
  list-style-type: none;
  display: block;
  /* If you use divider & borders, it's best to use top borders */
  /*border-top: 1px solid #ccc;*/
}
.dropdown-container .dropdown ul li:first-of-type {
  border: none;
  background-color: #f2f2f2;
}
.dropdown-container .dropdown ul li p {
  padding: 10px 15px;
  margin: 0;
}
.dropdown-container .dropdown ul li a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
  text-decoration: none;
  line-height: 1;
  color: #333;
}
.dropdown-container .dropdown ul li a:hover {
  color: #ff34b2;
}
.dropdown-container .dropdown ul li:first-of-type {
  border-radius: 10px 10px 0 0;
}
.dropdown-container .dropdown ul li:last-of-type {
  border-radius: 0 0 10px 10px;
}
.dropdown-container .dropdown ul li.divider {
  border: none;
  border-bottom: 1px solid #333;
  /* 
   * removes border from Li after the divider element
   * best used in combination with top borders on other LIs 
   */
}
.dropdown-container .dropdown ul li.divider ~ li {
  border: none;
}


 .badge {
        position: absolute;
        top: 1px;
        left:30%;
        padding: 5px 7px;
        border-radius: 50%;
        background: #F06548;
        color: white;
      }





.block {
  display: block;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}
@media (max-width: 480px) {
  .offcanvas-body{
    display: block !important;
  }

  .navbar-toggler {
    order: -1; /* Move the toggler to the left */
    margin-right: 3rem; /* Add some spacing between the toggler and the brand */
}
.navbar-brand {
    order: 0; /* Move the brand to the middle */
    margin: 0 auto; /* Center the logo */
    display: block;
    text-align: center;
}
.navbar-nav .nav-item4 {
    order: -1; /* Move the nav items to the right */
}


.login-btn{
  margin-right: -15px !important;

  margin: 0px;
}
.login-btn{

 
  border-right: 0px solid #8B8B8B28;
  padding: 0px;

}
.navbar-brand{

  /* background-color: #3BB5A0;
  height: 185px;
  width: 100%; */
  padding: 0px;
}

/* CSS */
.offcanvas {
  width: 80%;
}

.green-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 1rem;
}

.offcanvas-header {
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #3bb5a0;
  width: 100%;
  padding: 1rem;
}

.off-canvas-sign-up {
  border: 0px;
  width: 266px;
  max-width: 266px;
  height: 38px;
  background: #95d354 0% 0% no-repeat padding-box;
  margin-top: 1rem;
  border-radius: 4px;
  color: white;
  font-weight: 700;
}

.offcanvas-body {
  padding: 0px;
}

.offcanvaslist {
  list-style-type: none;
  padding: 0px;
}

.offcanvaslist-item {
  padding: 1rem;
  border-bottom: 1px solid #d9d9d9;
  font-size: 16px;
  font-weight: 500;
}

.btn-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}


.dddd{
  margin-right: 0rem;
}
.navbar-nav{
  margin-left: 0px !important;
}
}
/* //pad */

@media only screen and (min-width: 834px)  and (max-width: 1366px)  {

  .signup-btn{
    display: none;
    border: 0px;
    width: 106px;
    height: 38px;
    background-color:#95D354;
    border-radius: 4px;
    color: white;
    z-index: 6;
  }
  
  .offcanvas-body{
    display: nones !important;
  }
  
  .navbar-toggler {
    order: -1; /* Move the toggler to the left */
    margin-right: 3rem; /* Add some spacing between the toggler and the brand */
  }
  .navbar-brand {
    order: 0; /* Move the brand to the middle */
    margin: 0 auto; /* Center the logo */
    display: block;
    text-align: center;
  }
  .navbar-nav .nav-item4 {
    order: -1; /* Move the nav items to the right */
  }
  
  .login-btn{
  margin-right: -15px !important;
  
  margin: 0px;
  }
  .login-btn{
  
  
  border-right: 0px solid #8B8B8B28;
  padding: 0px;
  
  }
  .navbar-brand{
  
  /* background-color: #3BB5A0;
  height: 185px;
  width: 100%; */
  padding: 0px;
  }
  
  /* CSS */
  .offcanvas {
  width: 80%;
  }
  
  .green-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 1rem;
  }
  
  .offcanvas-header {
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #3bb5a0;
  width: 100%;
  padding: 1rem;
  }
  
  .off-canvas-sign-up {
  border: 0px;
  width: 266px;
  max-width: 266px;
  height: 38px;
  background: #95d354 0% 0% no-repeat padding-box;
  margin-top: 1rem;
  border-radius: 4px;
  color: white;
  font-weight: 700;
  }
  
  .offcanvas-body {
  padding: 0px;
  }
  
  .offcanvaslist {
  list-style-type: none;
  padding: 0px;
  }
  
  .offcanvaslist-item {
  padding: 1rem;
  border-bottom: 1px solid #d9d9d9;
  font-size: 16px;
  font-weight: 500;
  }
  
  .btn-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  }
  
  
  .dddd{
  margin-right: 0rem;
  }
  .navbar-nav{
  margin-left: 0px !important;
  }
}


/* //tablet */
@media screen and (min-width: 768px) and (max-width: 991px) {

.signup-btn{
  display: none;
  border: 0px;
  width: 106px;
  height: 38px;
  background-color:#95D354;
  border-radius: 4px;
  color: white;
  z-index: 6;
}

.offcanvas-body{
  display: block !important;
}

.navbar-toggler {
  order: -1; /* Move the toggler to the left */
  margin-right: 3rem; /* Add some spacing between the toggler and the brand */
}
.navbar-brand {
  order: 0; /* Move the brand to the middle */
  margin: 0 auto; /* Center the logo */
  display: block;
  text-align: center;
}
.navbar-nav .nav-item4 {
  order: -1; /* Move the nav items to the right */
}


.login-btn{
margin-right: -15px !important;

margin: 0px;
}
.login-btn{


border-right: 0px solid #8B8B8B28;
padding: 0px;

}
.navbar-brand{

/* background-color: #3BB5A0;
height: 185px;
width: 100%; */
padding: 0px;
}

/* CSS */
.offcanvas {
width: 80%;
}

.green-container {
display: flex;
justify-content: center;
align-items: center;
text-align: center;
flex-direction: column;
padding: 1rem;
}

.offcanvas-header {
justify-content: center;
align-items: center;
text-align: center;
background-color: #3bb5a0;
width: 100%;
padding: 1rem;
}

.off-canvas-sign-up {
border: 0px;
width: 266px;
max-width: 266px;
height: 38px;
background: #95d354 0% 0% no-repeat padding-box;
margin-top: 1rem;
border-radius: 4px;
color: white;
font-weight: 700;
}

.offcanvas-body {
padding: 0px;
}

.offcanvaslist {
list-style-type: none;
padding: 0px;
}

.offcanvaslist-item {
padding: 1rem;
border-bottom: 1px solid #d9d9d9;
font-size: 16px;
font-weight: 500;
}

.btn-close {
position: absolute;
top: 0.5rem;
right: 0.5rem;
}


.dddd{
margin-right: 0rem;
}
.navbar-nav{
margin-left: 0px !important;
}
}


