.swiper-reverse{

    height: 737px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

}

.card12{
  display: none;
}

.card10 {
    width: 60% ;
    height: 560px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-right: 5%;
    margin-left: 2%;


}

.card10-body {
    text-align: end;
}

.card-reverse-title-1 {

    font-size: 16px;
    font-weight: 600;


}

.card-reverse-title-2 {

    font-size: 42px;
    font-weight: bold;

}

.card-reverse-title-3 {

    font-size: 22px;

}

.card11 {
    background-color: #9EBE5A;
    width: 570px;
    height: 560px;
    padding-right: 5%;
    padding-left: 5%;
    border-radius: 8px;
}

.card11-body {
  
    text-align: end;

}
.contact-info1 {
    width: 50%;
    display: flex;
    flex-direction: row-reverse;
    float: left;
    padding-top: 1.5rem;    
    
  }
  
  .contact-info-icon1 {
    text-align: end;
  }
  
  .card11-text{

    color:white;
    font-size: 18px;

  }
  
  
  
  .contact-info-content1 {
    padding-left: 1.5rem;

  }
  
  .contact-info-content1 h4 {
    color: white;
    font-weight: bold;
    font-size: 1.4em;
    margin-bottom: 5px;
    width: 300px;
    margin-top: 1rem;
  }
  
  .contact-info-content1 p {
    color: #E6E6E6;
    font-size: 1em;
  }

.navigation1{

  display: flex;
  flex-direction: row;
  column-gap: 2rem;
    padding-right: 10%;
    padding-top: 1rem;
}

.swiper-button-prev1 {
  background-image:url('/webapps/lms_react/src/assets/Group 23.svg');
  color: transparent;
  background-color: transparent; 
  width: 50px;
  height: 50px;
  cursor: pointer;
  

}

.swiper-button-next1
 {
  background-image:url('/webapps/lms_react/src/assets/Group 24.svg');
  color: transparent;
  background-color: transparent; 
  width: 50px;
  height: 50px;
  cursor: pointer;

 }


.swiper-button-next1:hover,
.swiper-button-prev1:hover {
  background-color: transparent; 
  color: #e6e5f3;
  color:transparent;
}

.swiper-button-next1:active,
.swiper-button-prev1:active {
  background-color:transparent;
  border: 0px;
  color: transparent !important;
}

@media (max-width: 480px) {

.swiper-reverse{
width: 100%;
height: auto;
display: flex;
}
.card11 {
  background-color: #9EBE5A;
  width: 100%;
  height: 630px;
  padding-right: 5%;
  padding-left: 5%;
  border-radius: 8px;
}

.card10{
  display: none !important;
  width: 80%;
  margin-right: 0%;
  margin-left: 0%;
  display: flex;
  justify-content: start;
  align-items: start;
  text-align: start;
  padding-top: 1rem;
  
}
.card-reverse-title-2 {

  font-size: 30px !important;
  font-weight: bold;

}
.card10-body {
  text-align: end;
}

.card-reverse-title-3 {

  font-size: 22px;
  width: 100%;

}
.card12{
  display: block;
  width: 94%;
  margin-right: 0%;
  margin-left: 0%;
  display: flex;
  justify-content: start;
  align-items: start;
  text-align: start;
  padding-top: 1rem;
  padding-bottom: 1rem;
}


}


@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .card10{
    display: none !important;
    width: 80%;
    margin-right: 0%;
    margin-left: 0%;
    display: flex;
    justify-content: start;
    align-items: start;
    text-align: start;
    padding-top: 1rem;
    
  }

  .navigation1{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0px;
    margin-left: 5%;
  }
  .card12{
    display: block;
    width: 94%;
    height: 100% !important;
    margin-right: 0%;
    margin-left: 0%;
    display: flex;
    justify-content: start;
    align-items: start;
    text-align: start;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .swiper-reverse{

    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    
}
}
