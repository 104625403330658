.course-sec-mid-content {


  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 3rem;

}

.course-section-title {

  font-weight: 700;
  font-size: 16px;

}


.course-section-title2 {

  font-weight: 700;
  font-size: 34px;

}

.menu-list ul {

  list-style-type: none;
  display: flex;
  gap: 5%;
  background-color: #EAF5FA;
  width: 70%;
  height: auto;
  padding-left: 0px;
  margin-bottom: 0px;
}

.menu-list ul li {

  font-size: 18px;
  color: black;
  padding: 10px;
  padding-top: 30px;
  cursor: pointer;

}

.menu-list {

  width: auto !important;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;

}




.menu-list ul li a {


  text-decoration: none;
  color: black;



}

.all {


  color: #00AAEF;
  width: 78px;
  height: 75px;
  margin-top: -30px;
  border: 0px;
  border-radius: 4px;
  font-weight: 600;
  background-color: transparent;

}

.List-item3.active {
  background-color: #d2dce1;
  font-weight: 600;
  color: #00AAEF;
  width: auto;
  height: auto;
  border-radius: 4px;
  padding: 10px;
  padding-top: 30px;
  cursor: pointer;
}


.list_of_cards {

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 2rem;
  column-gap: 3%;
}

.card-level {
  position: relative;

}

.card-level p {
  padding: 5px;
  position: absolute;
  border-radius: 24px;
  background-color: #50C4EE;
  color: white;
  text-align: center;
  top: -15rem;
  left: 1.2rem;
  font-size: 16px;
  font-weight: 500;
  width: 126px;
  height: 47px;
  background: #009CE4 0% 0% no-repeat padding-box;
  border-radius: 4px 0px 14px 0px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 23px;
  padding-right: 23px;
}


.card-new2 {
  width: 370px;
  height: 540px;
  border: 1px solid #E9E9E9;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
}

.card-new-img2 {

  width: 330px;
  height: 220px;
  margin: 20px;
}

.circled-techer-img2 {
  width: 33px;
  height: 33px;
  margin: 20px;
  margin-top: 0px;
}

.card-body-new2 {

  margin: 20px;
  margin-top: 0px;

}

.nameoftecher2 {
  color: #292929;
}

.card-title-new2 {
  font-size: 18px;
  font-weight: 700;
  color: #292929;
}


.card-text-new2 {
  color: #707070;
  font-size: 16px;
}

.card-new-btn2 {
  width: 100%;
  height: 48px;
  background: #95D354 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: 0px;
  color: white;
  cursor: pointer;
}

.lessons_number_new {

  font-size: 16px;
}

.lessons_number_new img {

  margin-right: 3px;
}

.price_btn_new {
  font-size: 16px;
  color: #292929;
  float: right;
}

.price_btn_new span {

  color: #009CE4;

}

.card-numbers_hours {
  position: relative;

}

.card-numbers_hours p {
  position: absolute;
  color: #009CE4;
  text-align: center;
  top: -5rem;
  left: 2rem;
  padding-top: 5px;
  font-size: 16px;
  font-weight: 400;
  width: 95px;
  height: 37px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px;
}

.card-numbers_hours span {
  color: black;
}

@media (max-width: 480px) {

  .manu-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

  }

  .menu-list ul {

    list-style-type: none;
    overflow-x: auto;
    gap: 1%;
    background-color: #EAF5FA;
    width: 100%;
    padding-left: 0px;
    margin-bottom: 0px;
  }

  /* .list_of_cards .card-new2 {
    display: none;
  }

  .card-new2:nth-child(1) {
    display: block;
  } */

  .list_of_cards {
    row-gap: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .card-new2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 340px;
    height: 540px;
    border: 1px solid #E9E9E9;
    border-radius: 6px;
  }

  .card-new-img2 {

    width: 300px;
    height: 220px;
    margin: 20px;
  }

  .card-new-btn2 {
    width: 100%;
    height: 48px;
    background: #95D354 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: 0px;
    color: white;
    cursor: pointer;
  }


}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  
  .menu-list ul {

    list-style-type: none;
    display: flex;
    background-color: #EAF5FA;
    width: 100% ;
    overflow: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    padding-left: 0px;
    margin-bottom: 0px;
  }

  .list_of_cards {
    display: flex;
    overflow-x: auto;
    row-gap: 0px !important;
    column-gap: 5%;
    row-gap: 1%;

  }
  .card-new2 {

    margin-left: 0% !important;
  
  }

 
}

