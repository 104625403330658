.details2-background {
    background-image: url('/webapps/lms_react/src/assets/Group 202.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
}

.card-table {
    width: 500px !important;
    padding-top: 1rem;

}

.table-container{
    width: 100%;
    overflow-x: scroll;
}

.card-table .card-body {
    margin-top: 4%;
}

.card-table .card-text {

    font-size: 14px;
    color: #292929;
    text-align: start;
    padding-left: 5%;
}

.table {
    border-collapse: collapse;
    overflow-x: auto;
}


.table-bordered thead tr th {

    background-color: #F7F7F7 !important;
    border: 0px !important;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 500;
    font-size: 15px;
    /* border-radius: 8px !important; */


}

.table-bordered thead {

    border-color: #E0E0E0 !important;
    border: 1px solid #E0E0E0;
    text-align: center;



}



.table-bordered tbody tr td {

    background-color: #F7F7F7 !important;
    border: none !important;
    
}

.table-bordered tbody  {

    border:1px solid #E0E0E0;
    
    
}

td {

    height: 80px;
    width: 160px;
    text-align: center;
    vertical-align: middle;


}

.table-bordered tbody th {

    background-color: #F7F7F7 !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    border: none;
}

.table-bordered tbody td {

    justify-content: center;
    align-items: center;
    text-align: center;

}




.removetablecol {
    width: 80px;
}

.close:before {
    content: "\d7";
    font-size: 32px;
    color: #F06548;
    margin-bottom: 30px !important;
}



.quantity-btn {

    background-color: white;
    border-radius: 25px;
    width: 100px;
    height: 50px;
    border: 0px;

}
.thirdsec{
    border-top: 1px solid  #E0E0E0;
    border-bottom: 1px solid #E0E0E0;

}
.thirdsec p{
font-size: 18px !important;
color: #292929;
font-weight: 500;
padding-top: 1.8rem;
}

.thirdsec span{
font-size: 18px !important;
color: #292929;
font-weight: 500;
float: right;
margin-top: -2.5rem;

}

.sec-table{

    display: flex;
    justify-content: center;
}

.second-data-table{


    width: 650px;
height: 298px;
}

.second-data-table tr{

    border-bottom: 1px solid #E0E0E0;
}

.table-title{

font-size: 24px;
font-weight: 400;
color: #292929;

}

.table-subtotal-title{
    font-weight: 400;
    font-size: 16px;

}

.second-data-table tr td{
    color:#646464 ;
    font-size: 16px;

}

.mid-sec-titles th span {

    font-size: 15px;
}

.mid-sec-titles th{

    font-size: 15px;
    font-weight: 400;
    padding-top: 1rem;
    padding-bottom: 1rem;
    
    }

    .mid-sec-titles span{

        color: #636363;
        padding-top: 0.5rem;
        }


  .total55 th {

    font-size: 18px !important;
    color: #292929;
    font-weight: 500;
  }  
  
  
  .total55 td {

    font-size: 18px !important;
    color: #292929;
    font-weight: 500;
  }      


  .proceed-btn{
    background-color:#95D354 ;
    border-radius: 6px;
    width: 212px;
    height: 50px;
    cursor: pointer;
    border: 0px;
    color: white;

  }

  .center-btn-proceed{
    display: flex;
    justify-content: center;
    padding-bottom: 3rem;
    padding-top: 2rem;
    
  }

  .empty-cart{
    justify-content: center;
    text-align: center;
    padding-top: 1rem;
  }

  .empty-cart h6{

    padding-top: 3rem;
    font-size: 24px;
    color: #343434;
    font-weight: 300;
    padding-bottom: 2rem;
  }


  .empty-cart button{
    width: 200px;
    height: 50px;
    background-color: #95D354;
    color: #FFFFFF;
    font-size: 16px;
    border: 0px;
    border-radius: 5px;
    margin-bottom: 3rem;
    

    
  }

  .blue-banner2  {

    background-color: #4878E6;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 196px;

}

.card-title-newsletter {
    font-size: 28px !important;
    padding: 0px !important;
}
.card-text-newsletter {
    font-size: 18px !important;
    color: #FAFAFA !important;
    font-weight: 200;
    width: 470px;
}
.blue-banner2 h5{

    padding-top: 4rem;
    color: white;
    font-size: 24px;
    font-weight: 700;
    padding-left: 4rem;


}
.grid-layouts{

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15%;
}
.cardnewsletter{
    margin-top: 3.7rem;
    width: 500px;
}
.input-btn-sec {
display: flex;
justify-content: center;
align-items: center;
position: relative;
margin-top: 2rem;

}
.input-btn-sec input{
    width: 477px !important;
    height: 66px !important;
    padding: 10px;
    border-radius: 8px;
    border: 0px;

    
}

.join-btn{
    background-color: #383838  !important;
    color: white;
    font-weight: 700;
    width: 105px;
    height: 50px;
    border-radius: 8px;
    border: 0px;
    position: absolute;
    right:8%;    

}



@media (max-width: 480px) {

    .blue-banner2  {

        background-color: #4878E6;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 100%;
    
    }
    .card-title-newsletter {
        font-size: 20px !important;
        padding: 0px !important;
        padding-top: 1rem !important;
    }

    .card-text-newsletter{

        font-size: 18px;
        width: 100%;
    }

    .grid-layouts{

        display: flex;
        flex-direction: row;
        column-gap: 0%;
        height: 100%;

    }
    .cardnewsletter{
        margin-top: 2.7rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .input-btn-sec{

        padding-bottom: 1rem;

    }

    .input-btn-sec input{
        width: 100% !important;
        height: 66px !important;
        padding: 10px;
        border-radius: 8px;
        border: 0px;
    
        
    }
    
    .join-btn{
        background-color: #383838  !important;
        color: white;
        font-weight: 700;
        width: 105px;
        height: 45px;
        border-radius: 8px;
        border: 0px;
        position: absolute;
        right:5%;
    }

    


    
}


