.text-section h5 {
  color: #292929;
  font-size: 28px;
  font-weight: 700;
}

.text-section p {
  color: #989898;
  font-size: 18px;
  font-weight: 300;
}

.text-section {
  display: inline;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 3rem;
}

.ask-container {
  padding-top: 3rem;
}

.accordion {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;


}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E");
  background-color: transparent !important;


}

.accordion-button {

  font-size: 20px;
  font-weight: 700;
  border: 0px;

}



button.accordion-button:focus {
  box-shadow: none;
  outline: none;
  background-color: transparent;

}

.accordion-item {
  width: 800px;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 0 !important;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.accordion .accordion-item+.accordion-item {
  border-top: 1px solid #D9D9D9;
}

#accordionExample .accordion-item {
  background-color: transparent;
  border-top: 1px solid #D9D9D9 !important;
  border-bottom: 1px solid #D9D9D9 !important;
  border-left: 1px solid #D9D9D9 !important;
  border-right: 1px solid #D9D9D9 !important;
}

.accordion-button:not(.collapsed) {
  background-color: white !important;
  border: 0px;
  color: inherit;
}

.accordion-body {
  background-color: white !important;

}

#collapse1{

  background-color: white !important;
}

.accordtion2 .accordion-button:not(.collapsed) {
  background-color: white !important;
  box-shadow: none;
}
.accordtion2 .accordion-header {

  background-color: white !important;


}

.accordtion2 .accordion-button{

  background-color: white  !important;
  border: 0px ;
}

@media (max-width: 480px) {

  .ask-container{

    padding-top: 0.5rem ;
  }

.text-section p {
  color: #989898;
  font-size: 18px;
  font-weight: 300;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}


.accordion-item{
width: 100%;
border: 1px solid  #D9D9D9 !important;
border-radius: 6px !important;

}
}