.titles h3 {

    font-size: 28px;
    font-weight: 700;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.titles span {

    font-size: 16px;
    font-weight: 300;
}

.Form-cotainer {
    display: flex;
    column-gap: 10%;
}

.form {

    width: 570px;
    height: 570px;
    background: #009CE4 0% 0% no-repeat padding-box;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.form input {

    width: 440px;
    height: 48px;
    margin-bottom: 1rem;
    border: 1px solid #DADADA;
    border-radius: 8px;
    padding-left: 15px;
    outline: none;
}

.form select {

    width: 440px;
    height: 48px;
    margin-bottom: 1rem;
    border: 1px solid #DADADA;
    border-radius: 8px;
    padding-left: 15px;
    outline: none;

}

.form textarea {

    width: 440px;
    height: 112px;
    border: 1px solid #DADADA;
    border-radius: 8px;
    padding-left: 15px;
    outline: none;
    padding-top: 10px;


}

.sendbtn {

    background-color: #1BDDAA;
    color: #fff;
    width: 180px;
    height: 48px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.wrappper2 {
    padding-bottom: 3rem;
}

.send-sec-btn {

    padding-top: 1rem;
    float: right;
}

@media (max-width: 480px) {

    .landing55 {

        display: none;
    }

    .landing33 {

        display: none;
    }

    .form {

        width: 366px;
        height: 570px;
        background: #009CE4 0% 0% no-repeat padding-box;
        border-radius: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center !important;
    }

    .Form-cotainer {
        display: block !important;

    }

    .wrappper2 {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        text-align: center;
        padding-bottom: 0px;
    }

    .titles h3 {

        font-size: 22px;
        font-weight: 700;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .send-sec-btn {

        padding-top: 0rem;
        float: inline-start;
        margin-right: 0px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
        margin-left: 10px !important;
        margin-right: 10px !important;
    }


    .sendbtn {

        background-color: #1BDDAA;
        color: #fff;
        width: 348px !important;
        height: 48px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
    }
}



@media screen and (min-width: 768px) and (max-width: 1024px) {

    .form input {
        width: 100%;
        height: 48px;
        margin-bottom: 1rem;
        border: 1px solid #DADADA;
        border-radius: 8px;
        padding-left: 15px;
        outline: none;
    }

    .form {

        width: 570px;
        height: 570px;
        background: #009CE4 0% 0% no-repeat padding-box;
        border-radius: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;
    
    }
}

