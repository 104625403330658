.timer-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    
  }
  
  .progress-bar {
    width: 100%;
    height: 20px;
    background-color: #eee;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow: hidden;
  }
  
  .progress-bar-fill {
    height: 100%;
    background-color: #95C728;
  }
  
  .timer-text {
    display: flex;
    font-size: 40px;
    font-weight: bold;
  
  }
  
  #labels {
    display: flex;
    font-size: 14px;
    display: flex;
    color: #808080;
  }
  #labels span {
font-size: 14px;
text-indent: 40px;


  }







  @media (max-width: 480px) {

  .timer-text {
    display: flex;
    font-size: 40px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    width: 100%;
  
  }
  
  #labels {
    display: flex;
    font-size: 14px;
    display: flex;
    color: #808080;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  #labels span {
font-size: 14px;
text-indent: 40px;


  }

  .timer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
}