.gallery-container {
  }
  
  .large-image {
    width: auto; /* Adjust the size as needed */
    height: 480px !important; /* Adjust the size as needed */
    background-color: #ccc; /* Example background color */
    margin-right: 10px; /* Adjust the spacing between large and small images */
    position: relative;
  }

  .largeimg{
    height: 480px !important; /* Adjust the size as needed */
    z-index: -1;
  }
  .small-image{

    width: auto;
  }

  .playbtn{
    z-index: 1;
    display: block;
    margin-left: auto;
    margin-right: auto;
    transform: translate(-20%, -280%);
    cursor: pointer;
  }
  
  
  .small-images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr ;
    column-gap:1%;
    row-gap: 2rem;

  }

 
 