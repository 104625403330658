.Login-card-img {
    width: 100%;
    height: 100vh;
}

.logo-img-container {
    margin-top: 15%;
    margin-bottom: 13%;

}

.card-login-body {
    padding-left: 5rem;
    padding-left: 5rem;
    width: 550px;
}

.mohamed{
  padding-top: 3rem;
  
    
}

.card-title5 {
    font-size: 34px;
    font-weight: 600;
    color: #343434;
}

.card-title6 {
    padding-top: 1rem;
    font-size: 24px;
    color: #343434;
    font-weight: 400;
    padding-bottom: 3rem;

}

.register-btn {
    color: white;
    font-weight: 700;
    width: 630px;
    height: 50px;
    background: #95D354 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: 0px;
}

.login-btn-section {

    margin-top: 5%;
}

.login-btn-section a {

    color: #81CB34;

}

.form-group101 label {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 200px;
}
.form-group101 input {
    width: 300px;
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EAECED;
    border-radius: 5px;
    padding: 1px ;
    padding-left: 10px;
}


.select-country label{
    padding-top: 10px;
    padding-bottom: 10px;
}


.select-country select{
    margin: 0px !important;
    width: 628px;
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EAECED;
    border-radius: 5px;
    font-size: 16px;
    padding: 1px ;
    padding-left: 10px;
    
}

.forgetpass-container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-top: 5px;
}

.forgetpass-container a {
    color: #81CB34;
    padding-right: 5px;

}

.centerdont {
    display: flex;
    justify-content: center;
    padding-top: 1rem;

}


.centerdont a {
    color: #81CB34;

}

.logo33{
    padding-top: 1rem;
    width: 230px;
}

.whole-flexing-signup {

    display: flex;

}

@media (max-width: 480px) {
    .register-btn {
        color: white;
        font-weight: 700;
        width: 100%;
        height: 50px;
        background: #95D354 0% 0% no-repeat padding-box;
        border-radius: 4px;
        border: 0px;
    }

    .whole-flexing-signup {

        display: flex;
        flex-direction: column;
    }

    .select-country select{
        margin: 0px !important;
        width: 100% !important;
        height: 50px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #EAECED;
        border-radius: 5px;
        font-size: 16px;
        padding: 10px ;
        
    }

    .form-group101 input {
        width: 100%;
        height: 50px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #EAECED;
        border-radius: 5px;
        padding: 1px ;
        padding-left: 10px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {


    .register-btn {
        color: white;
        font-weight: 700;
        width: 100%;
        height: 50px;
        background: #95D354 0% 0% no-repeat padding-box;
        border-radius: 4px;
        border: 0px;
    }

    .select-country select{
        margin: 0px !important;
        width: 100% !important;
        height: 50px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #EAECED;
        border-radius: 5px;
        font-size: 16px;
        padding: 10px ;
        
    }

    .form-group101 input {
        width: 100%;
        height: 50px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #EAECED;
        border-radius: 5px;
        padding: 1px ;
        padding-left: 10px;
    }


.whole-flexing-signup {

    display: flex;
    flex-direction: column;
}
}