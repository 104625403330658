.IMG{

    width: 100%;
    height: 25rem;
}
.container-on-img{

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: -25px;
    column-gap: 30px;
    background-color: white;

}

.card{

    width: 25rem;
    border: 0px;
    background-color: red;
}

.card1{
    width: 18rem;
}

.product-card img {

    width: 300px;
}
  
