.card15{
    margin-top: 3%;
    background-image: url('/webapps/lms_react/src/assets/Group 50.png');
    background-size: cover;
    width: 900px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

@media (max-width: 480px) {
  .card15 {
      width: 300px;
      background-image: url('/webapps/lms_react/src/assets/mobileview.png');
  }
  .card15-body{
   margin-left: 26% !important;
  }
  .card-category5{
    display: none;
  }
  .card15-title{

    font-size: 19px !important;
    width: 100% !important;
  }
  .card15-text{
    font-size: 19px;
  }
              

}

.card15-body{

    

}

.card-category5 {

    position: relative;
  
  }
  
  .card-category5 p {
  
    position: absolute;
    width: auto;
    padding: 10px;
    height: 28px;
    border-radius: 24px;
    background-color: #50C4EE;
    color: white;
    text-align: center;
    padding-top: 3px;
    top: -15rem;
    left: -7rem;
  
  }

.card15-title{

    color: white;
    font-weight: bold;
    font-size: 50px;
    margin-left: -20%;
    margin-top: 5%;

}
.card15-text{

    color: white;
    font-weight: bold;
    font-size: 22px;
    margin-left: -20%;
    margin-top: 5%;

}

.price_btn2{

        width: 90px;
        height: 48px;
        background-color: #95D354;
        border: 0px;
        font-weight: 700;
        font-size: 18px;
        margin-left: -20%;
        margin-top: 5%;    
}

.navigation{
    z-index: 2;
    display: flex;
    flex-direction: column;
    column-gap: 0.7rem;

}

.swiper-button-prev2 {
    background-image:url('/webapps/lms_react/src/assets/Group 24.svg');
    color: transparent;
    background-color: transparent; 
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-repeat: no-repeat;
    position: relative;
}
  
  .swiper-button-next2
   {
    background-image:url('/webapps/lms_react/src/assets/Group 23.svg');
    background-repeat: no-repeat;
    color: transparent;
    background-color: transparent; 
    width: 50px;
    height: 50px;
    cursor: pointer;

    
   }
  
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background-color: transparent; 
    color: #e6e5f3;
    color:transparent;
  }
  
  .swiper-button-next:active,
  .swiper-button-prev:active {
    background-color:transparent;
    border: 0px;
    color: transparent !important;
  }
  .combinedshape{
    z-index: -5555555;
   position: absolute;
   margin-left: 5%;
   margin-top: 8%;
  }

  @media (min-width: 768px) {

    .card15{
      margin-top: 3%;
      background-image: url('/webapps/lms_react/src/assets/Group 50.png');
      background-size: cover;
      width: 1000px;
      height: 600px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
  }


  .card15-title{

    color: white;
    font-weight: bold;
    font-size: 50px;
    margin-left: 0%;
    margin-top: 5%;

}

.card15-text{

  color: white;
  font-weight: bold;
  font-size: 22px;
  margin-left: 0%;
  margin-top: 5%;

}

.price_btn2{

  width: 90px;
  height: 48px;
  background-color: #95D354;
  border: 0px;
  font-weight: 700;
  font-size: 18px;
  margin-left: 0%;
  margin-top: 5%;    
}

  }
