.blue-ad {
    background-color: #4779E5;
    width: 100%;
    height: 56px;

}

.search-sec {

    height: 100%;
}

.blue-ad h6 {
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    color: white;
    padding-top: 0.9rem;
}

.blue-ad a {

    color: white;
}

.search-titles-sec {
    padding-top: 4rem;
}

.search-titles-sec h5 {

    color: #343434;
    font-weight: 700;
    font-size: 40px;

}

.search-titles-sec p {
    padding-top: 0.5rem;
    color: #343434;
    font-size: 14px;
    font-weight: 400;

}

.search-titles-sec span {

    color: #343434;
    font-weight: 600;

}

.dropdown1 {


    float: right;
    margin-top: -3.3rem;
    margin-right: -8%;
}

.dropdown1-toggle::after {
    content: url("/webapps/lms_react/src/assets/Chevron down.svg");
    margin-left: 1.5em;
    /* Adjust the spacing between the text and the icon */
    border: 0px;

}

.dropdown1 a {
    width: 214px;
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0px;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    color: #81838C;
    font-size: 16px;
    padding-top: 13px;

}

.dropdown1 :hover {
    background-color: #FFFFFF;
    color: #81838C;

}

.search-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 2.5%;
    column-gap: 1%;
    padding-bottom: 5rem;
}

.card369 {
    width: 275px !important;

}

.card369 .card-body {
    background-color: #EAF5FA;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 0.1rem;
}

.price_btn3 {

    width: 67px;
    height: 35px;
    border-radius: 4px;
    background: #95D354 0% 0% no-repeat padding-box;
    font-size: 12px;
    color: white;
    border: 0px;
    float: right;
    margin-top: -10px;

}



.card369 .card-body .card-title {

    font-size: 14px;
    height: 60px;
}

.card369 .card-body .card-text {

    font-size: 12px;
}

.lessons_number2 {

    font-size: 12px;
    color: #33333380;

}

.card-category2 {
    position: relative;

}

.card-category2 p {
    padding: 5px;
    position: absolute;
    width: auto;
    height: auto;
    border-radius: 24px;
    background-color: #50C4EE;
    color: white;
    text-align: center;
    top: -12rem;
    left: 1rem;
    font-size: 11px;
    font-weight: 500;

}


.card-level {
    position: relative;

}

.card-level p {
    padding: 5px;
    position: absolute;
    border-radius: 24px;
    background-color: #50C4EE;
    color: white;
    text-align: center;
    top: -15rem;
    left: 1.2rem;
    font-size: 16px;
    font-weight: 500;
    width: 126px;
    height: 47px;
    background: #009CE4 0% 0% no-repeat padding-box;
    border-radius: 4px 0px 14px 0px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 23px;
    padding-right: 23px;
}


.card-new {
    width: 370px;
    height: 540px;

}

.card-new-img {

    width: 330px;
    height: 220px;
    margin: 20px;
}

.circled-techer-img {
    width: 33px;
    height: 33px;
    margin: 20px;
    margin-top: 0px;
}

.card-body-new {

    margin: 20px;
    margin-top: 0px;

}

.nameoftecher {
    color: #292929;
}

.card-title-new {
    font-size: 18px;
    font-weight: 700;
    color: #292929;
}


.card-text-new {
    color: #707070;
    font-size: 16px;
}

.card-new-btn {
    width: 330px;
    height: 48px;
    background: #95D354 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: 0px;
    color: white;
    cursor: pointer;
}

.lessons_number_new {

    font-size: 16px;
}

.lessons_number_new img {

    margin-right: 3px;
}

.price_btn_new {
    font-size: 16px;
    color: #292929;
    float: right;
}

.price_btn_new span {

    color: #009CE4;

}

.card-numbers_hours {
    position: relative;

}

.card-numbers_hours p {
    position: absolute;
    color: #009CE4;
    text-align: center;
    top: -5rem;
    left: 2rem;
    padding-top: 5px;
    font-size: 16px;
    font-weight: 400;
    width: 95px;
    height: 37px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
}

.card-numbers_hours span {
    color: black;
}

.allcategory-sec {}

.allcategory-sec-title {
    padding-top: 10px;
    color: #212521;
    font-size: 18px;
    font-weight: bold;
}

.allcategories-list {
    list-style-type: none;
    padding-left: 0px;
    padding-top: 1rem;
    overflow-y: auto;
}

.allcategories-item {
    font-size: 18px;
}

.allcategories-item span {
    color: #A7A7A7;
}

.whole-filter-container {

    border: 1px solid #D9D9D9;
    background-color: white;
    margin-top: 2rem;
}

.btn-sec {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
}

.filter-btn {
    width: 303px;
    height: 48px;
    background: #009CE4 0% 0% no-repeat padding-box;
    border-radius: 8px;
    border: 0px;
    color: white;
    font-size: 18px;
}

.reset-filter-btn {
    width: 303px;
    height: 48px;
    background: transparent;
    border-radius: 8px;
    border: 0px;
    color: #009CE4;
    font-size: 18px;
}

.bottom-canvas-block {
    display: none;
}

.whole-filter-container {
    display: none;
}


.whole-flex {


    display: flex;
    column-gap: 1%;
    width: 100%;
    height: 100%;
}


.card-new-updated{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 330px;
    height: 540px;
    border: 1px solid #E9E9E9;
    border-radius: 6px;
    padding: 0.5rem;
  }

  .techersec_new{
       
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 0.3rem;
  }

  .tech-img-name{

    margin-top: 0.3rem;
  }

@media (max-width: 480px) {


    .search-cards {
        display: flex;
        flex-direction: column;
        row-gap: 1rem ;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }


    .blue-ad {
        background-color: #4779E5;
        width: 100%;
        height: 100px;
    }

    .search-titles-sec {
        padding-top: 1rem;
    }

    .search-titles-sec h5 {
        font-size: 20px;
    }


    .drop-sec {

        margin-bottom: 1rem !important;


    }

    .dropdown1 {
        margin-top: -0.3rem;
        margin-right: -2%;

    }


    .bottom-canvas-block {

        display: block;
        background-color: transparent;
        border: 0px;
        font-size: 14px;
        color: #343434;

    }


    .whole-filter-container {
        display: none;
    }

    .wid-of-img-filter {
        width: 24px;
        height: 21px;
        margin-right: 5px;
    }

    .offcanvas-bottom {
        width: 100%;
        height: 80% !important;
    }

    .accordion-item5 {

        width: 100%;
    }

    #offcanvasBottomLabel {

        color: #009CE4;
    }

    #bottomcanv {
        background-color: transparent;
        background: transparent;
        display: flex;
        justify-content: start;

    }

    #offcanvasBottom {

        border-radius: 10px;
    }

    .card-new-updated{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        clear: both;
        width: 100%;
        height: 540px;
        border: 1px solid #E9E9E9;
        border-radius: 6px;
        padding: 0.5rem;
      }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {


    .search-cards {
        display: grid;
        clear: both;
        justify-content: center;
        align-items: center;
        grid-template-columns: 1fr ;
        row-gap: 2.5%;
        column-gap: 3%;
        padding-bottom: 5rem;
    }
    .card-new-updated{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 330px;
        height: 540px;
        border: 1px solid #E9E9E9;
        border-radius: 6px;
        padding: 0.5rem;
      }

    .search-sec {

        height: auto;
    }
    .dropdown1 {
        margin-top: -0.3rem;
        margin-right: -2%;

    }

    .whole-flex {
        column-gap: 2%;
    }

}