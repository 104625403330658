.wrapper{
    margin-top: 50px;
    margin-left: 50px;

    background-color: white;
    width: 300px; 
    height: 100px;


}

.filter{
    width: 300px;
    height: auto;
    margin-top: 50px;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    background-color: white;
    

}

