.More-Courses{

    background-color: #F4F4F4;
    padding-top: 3rem;
    padding-bottom: 1rem;
  
  }

  .pre-text2 h3 {

    font-size: 40px;
    padding-bottom: 2rem;
}

.pre-text2  {
    justify-content: center;
    align-items: center;
    text-align: center;

}

.navigation3{
    z-index: 2;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 0%;
    padding-top: 1rem;
    flex-direction: row;
    column-gap: 0.7rem;

}

.swiper-button-prev3 {
    background-image:url('/webapps/lms_react/src/assets/Count.svg');
    color: transparent;
    background-color: transparent; 
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-repeat: no-repeat;
    position: relative;
}
  
  .swiper-button-next3
   {
    background-image:url('/webapps/lms_react/src/assets/Count (1).svg');
    background-repeat: no-repeat;
    color: transparent;
    background-color: transparent; 
    width: 40px;
    height: 40px;
    cursor: pointer;

    
   }

   .centerslidermob{


   }

   SwiperSlide{

   }


   @media (max-width: 480px) {


    .swiper-slide {
        flex-shrink: 0;
        width: auto;
        height: 100%;
        position: relative;
        transition-property: transform;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .navigation3{

        margin-left: 5%;
    }


    .swiper {
        padding: 0px;
    }


  .pre-text2 h3 {

    font-size: 30px;
    padding-bottom: 2rem;
}
   }



   @media only screen and (min-width: 768px) and (max-width: 1024px) {


    .swiper-slide {
        flex-shrink: 0;
        width: auto;
        height: 100%;
        position: relative;
        transition-property: transform;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   }