.Login-card-img {
    width: 100%;
    height: 100vh;
}

.logo-img-container {
    margin-top: 15%;
    margin-bottom: 13%;

}

.card-login-body {
    padding-left: 5rem;
    padding-left: 5rem;
    width: 550px;
}


.card-title5 {
    font-size: 34px;
    font-weight: 600;
    color: #343434;
}

.card-title6 {
    padding-top: 1rem;
    font-size: 24px;
    color: #343434;
    font-weight: 400;
    padding-bottom: 3rem;

}

.Login-btn {
    color: white;
    font-weight: 700;
    width: 465px;
    height: 50px;
    background: #95D354 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: 0px;
}

.login-btn-section {

    margin-top: 5%;
}

.login-btn-section a {

    color: #81CB34;

}

.card-login{
    height: 100%;
}

.form-group100 label {
    padding-top: 10px;
    padding-bottom: 10px;
}
.form-group100 input {
    width: 465px;
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EAECED;
    border-radius: 5px;
    padding: 1px ;
    padding-left: 10px;
    color: #EAECED;

}

.select-country label{
    padding-top: 10px;
    padding-bottom: 10px;
}




.forgetpass-container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-top: 5px;
}

.forgetpass-container a {
    color: #81CB34;
    padding-right: 5px;

}

.centerdont {
    display: flex;
    justify-content: center;
    padding-top: 1rem;

}


.centerdont a {
    color: #81CB34;

}

.logo55{
    display: none;
}
@media (max-width: 480px) {

    .logo55{
        display: block;
    }


    .card-login{

        width: 100%;
    }

    .Login-card-img {
        width: 100%;
        height: 100% !important;
        padding-top: 0.5rem !important;
    }

    .rowforimg{

        padding: 0px;
    }

    .logo55{

        padding-top: 0.5rem;

    }
    
    .logo33{

        display: none;
    }
    .card-login-body {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        width: 100% !important;
        padding-top: 0px !important;
    }

    .card-title6 {
    font-size: 24px;
    padding-bottom: 0.5rem !important;

}

.form-group100 input {
    width: 100% ;
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EAECED;
    border-radius: 5px;
    padding: 1px ;
    padding-left: 10px;
    color: #EAECED;

}

.Login-btn {

    width: 100% 
    
}
.centerdont {
    display: flex;
    justify-content: center ;
    padding-top: 1rem;

}

.logo-img-container {
    margin-top: 9px !important;
    margin-bottom: 5px !important;

}

.card-title5 {
    font-size: 30px !important;
    font-weight: 500 !important;
    color: #343434;
}

.card-title6 {
    padding-top: 0.2rem !important;
    font-size: 24px;
    color: #343434;
    font-weight: 400;
    padding-bottom: 3rem;

}

.login-btn-section {

    margin-top: 5%;
    margin-bottom: 5% !important;
}



}



@media screen and (min-width: 768px) and (max-width: 1024px) {

    .logo55{
        display: block;
    }

    .card-login{
        min-height: 75vh;
        height: 100%;
    }
    .card-login{

        width: 100%;
    }

    .Login-card-img {
        width: 100%;
        height: 100% !important;
        padding-top: 0.5rem !important;
    }

    .rowforimg{

        padding: 0px;
    }

    .logo55{

        padding-top: 0.5rem;

    }
    
    .logo33{

        display: none;
    }
    .card-login-body {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        width: 100% !important;
        padding-top: 0px !important;
    }

    .card-title6 {
    font-size: 24px;
    padding-bottom: 0.5rem !important;

}

.form-group100 input {
    width: 100% ;
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EAECED;
    border-radius: 5px;
    padding: 1px ;
    padding-left: 10px;
    color: #EAECED;

}

.Login-btn {

    width: 100% 
    
}
.centerdont {
    display: flex;
    justify-content: center ;
    padding-top: 1rem;

}

.logo-img-container {
    margin-top: 9px !important;
    margin-bottom: 5px !important;

}

.card-title5 {
    font-size: 30px !important;
    font-weight: 500 !important;
    color: #343434;
}

.card-title6 {
    padding-top: 0.2rem !important;
    font-size: 24px;
    color: #343434;
    font-weight: 400;
    padding-bottom: 3rem;

}

.login-btn-section {

    margin-top: 5%;
    margin-bottom: 5% !important;
}




}