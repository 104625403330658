* {
  font-family: "Poppins" !important;
}

.first-section {
  background-image: url("/webapps/lms_react/src/assets/Bg.png");
  height:100%;
  width: 100%;
  background-size: cover;

}


.second-section {
  padding-top: 3rem;
  display: flex;
  column-gap: 20rem;
  font-family: 'Poppins';

}

.app-img1 {
  width: 96px;
  height: 96px;
}

.card1{

  width: auto !important;
  height: auto;

}


.card1-title {

  font-weight: 700;
  padding-top: 1.5rem;
  font-size: 24px;


}

.card1-text {
  padding-top: 0.3rem;
  color: #33333380;
  font-size: 18px;


}

.read-more {
  font-size: 18px;
  font-weight: 600;

}

.card-horizontal {
  display: flex;
  justify-content: center ;
  align-items: center;
}

.card-horizontal2 {
  display: flex;
  justify-content: center ;
  align-items: center;
}

.card-photo {

  width: 35.625rem;
  height: 35rem;
  margin-left: 20%;

}
.card-photo2{

  width: 35.625rem;
  height: 35rem;
  margin-left: -30% ;


}

.img-square-wrapper {
  width: 30%;
}


.card5-body {
  pointer-events: none;
  width: 70%;
  height: 42.875rem;
  background-color: #EAF5FA;
  z-index: -1;
  padding-left: 20rem;
  padding-top: 10rem;
  padding-right: 5rem;
}

.card6-body {
  width: 70%;
  height: 46.875rem;
  background-color: #EAF5FA;
  z-index: -1;
  padding-left: 9%;
  padding-top: 8rem;
  padding-right: 5rem;
  pointer-events: none;


}

.card-title-1 {

  font-size: 16px;
  font-weight: 700;

}

.card-title-2 {
  font-weight: 700;
  font-size: 56px;
  width: 60%;
  padding-top: 1.5rem;


}

.card-title-3 {

  font-size: 22px;

  padding-top: 1.5rem;

}

.card-par {
  padding-top: 2rem;

  font-size: 18px;
  color: #33333380;

}

.card5 {

  padding: 0px !important;

}
.explore-sec{
  z-index: 1000 !important;
  position: relative;
}
.explore-btn {

  background-color: #95D354;
  width: 170px;
  height: 48px;
  border-radius: 8px;
  color:white ;
  font-weight: bold;
  cursor: pointer;
  border: 0px;
  position: absolute;
  z-index: 1000 !important;

}




.card {

  background-color: #EAF5FA;
  border-radius: 8px;
  width: auto;


}

.lessons_number {

  font-size: 18px;
  color: #84818A;
  padding-bottom: 10px;

}


.price_btn {


  float: right;
  width: 90px;
  height: 48px;
  background-color: #95D354;
  border: 0px;
  font-weight: 700;
  font-size: 18px;
  margin-top: -10px;
}

.card-title {

  font-weight: 700;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.card-text {
  font-size: 18px;
  color: #84818A;
}

.loadmore-btn {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.load-more-btn {
  width: 170px;
  height: 48px;
  background: #00AAEF;
  border-radius: 4px;
  font-weight: 700;
  border: 0px;
  color: white;

}

.card-category {

  position: relative;

}


.card-category p {

  position: absolute;
  width: auto;
  padding: 10px;
  height: 28px;
  border-radius: 24px;
  background-color: #50C4EE;
  color: white;
  text-align: center;
  padding-top: 3px;
  top: -18rem;
  left: 2.5rem;
  font-size: 14px;

}

.findsection {

  background-color: #F4F4F4;
  padding-top: 3rem;


}

.companies_section {

  justify-content: center;
  align-items: center;
  text-align: center;
}


.companies_section h6 {

  font-size: 24px;
  color: black;
  font-weight: 700;

}

.companies_section h5 {

  font-size: 16px;
  color: black;
  font-weight: 500;

}

.compaines {

  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding-top: 3rem;
  padding-bottom: 5rem;


}

.techers-section {
  justify-content: center;
  align-items: center;
  text-align: center;
}



.techers-container h6 {

  font-size: 16px;
  font-weight: 500;


}

.techers-container h5 {

  font-size: 34px;
  font-weight: 700;


}

.card-body-techer {

  background-color: #EAF5FA !important;
  border-radius: 6px;
  padding-bottom: 10px;



}
.tech-img-container{
  background-color: #EAF5FA !important;
  border-radius: 6px;

}

.card0 {
  width: 300px;
  height: 347px;
}

.techers-cards {

  padding-bottom: 5rem;


}


.techer-img {

  width: 270px;
  height: 270px;
  margin-left: -6rem;


}

.techer-title{

  font-size: 24px !important;
  font-weight: 700;
  padding-top: 1rem;

}

.teacher-text{

color:#333333B2 ;
font-size: 18px !important;
font-weight: 500 !important;

}

.social-icon{

  width: 48px;
  height: 48px;
  border-radius: 11px;
  background-color: 333333B2;

}

.techers-cards{

  display: grid;
grid-template-columns: 1fr 1fr 1fr;  
column-gap: 10%;
padding-top: 2rem;

}

.social-icon2{

  padding: 4px;
  width: 48px;
height: 48px;
background-color: red;
border-radius: 15px;

}


.contact-info {
  width: 50%;
}

.contact-info-item {
  display: flex;
  margin-bottom: 30px;

}



.contact-info-icon i {
  font-size: 30px;
  line-height: 70px;
}

.contact-info-content {
  margin-left: 20px;
}

.contact-info-content h4 {
  color: #808080;
  font-size: 1.4em;
  margin-bottom: 5px;
}

.contact-info-content p {
  color: #808080;
  font-size: 1em;
}

.video-section {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.video-container h6 {

  font-size: 16px;
  font-weight: 500;


}

.video-container h5 {

  font-size: 34px;
  font-weight: 700;


}
.contact-info-content h4{
} 


.swiper-reverse{


  background-color: #EAF5FA;
}

@media (max-width: 480px) {

.card-horizontal {
  display: flex;
  flex-direction: column;
  justify-content: start !important ;
  align-items: self-start !important;

  
}

.card-photo{
  width: 340px !important;
  height: 360px;
  margin-left: 0px !important ;
  margin: 0px;
  padding: 0px;
  z-index: 9999;
  
  
}
.img-square-wrapper {
  width: 100% !important;
  justify-content: center;
  align-items: center;
  display: flex;

    }
    

.card-title-1{
  font-size: 16px;
}
.card-title-2{
  font-size: 30px;
  width: 100%;
}
.card-title-3{
  font-size: 22px;
}
.card-par{
  font-size: 18px;
}
.card5-body{
  height: 35.875rem;
  padding-left: 0px;
  padding-top: 3rem;
  width: 100%;
  z-index: -1;
  justify-content: center;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 0px;
}

.card-horizontal2 {
  display: flex;
  justify-content: center ;
  align-items: center;
  flex-direction:column-reverse;
}
.card-photo2{
  width: 340px !important;
  height: 360px;
  margin-left: 0px !important ;
  margin: 0px;
  padding: 0px;
  z-index: 9999;
}
.card6-body{
  padding-left: 0px;
  padding-top: 3rem;
  width: 100%;
  height: 100%;
  z-index: -1;
  justify-content: center;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
}
.contact-info-content h4{
  font-size: 24px;
  width: 100%;

}
.contact-info-content p{
  font-size: 18px;
  width: 100%;


}
.contact-info{
  width: 100%;
}


}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .card-horizontal {
    display: flex;
    flex-direction: column;
    justify-content: center !important ;
    align-items: center !important;
  
    
  }
  .card-photo{
    width: 704px !important;
    height: 690px;
    margin-left: 0px !important ;
    margin: 0px;
    padding: 0px;

  }
  .img-square-wrapper {
    width: 100% !important;
    justify-content: center;
    align-items: center;
    display: flex;
  
      }

    
    .card5-body{
      height: 35.875rem;
      padding-left: 0px;
      padding-top: 3rem;
      width: 100%;
      z-index: -1;
      justify-content: center;
      align-items: center;
      padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 0px;
    }
    .card-horizontal2 {
      display: flex;
      justify-content: center ;
      align-items: center;
      flex-direction:column-reverse;
    }
    .card-photo2{
      width: 704px !important;
      height: 690px;
      margin-left: 0px !important ;
      margin: 0px;
      padding: 0px;
      z-index: 9999;
    }

    .card6-body{
      padding-left: 0px;
      padding-top: 3rem;
      width: 100%;
      height: 100%;
      z-index: -1;
      justify-content: center;
      align-items: center;
      padding-left: 10%;
      padding-right: 10%;
    }



}
